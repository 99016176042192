const DEV_PB_URL = 'https://dev.pig-brother.com'
const STAGING_PB_URL = 'https://staging.pig-brother.com'
const PREVIEW_PB_URL = 'https://preview.pig-brother.com'
const PROD_PB_URL = 'https://pig-brother.com'
const DEV_DC_URL = 'https://dev.data-collector.vetvise.com'
const STAGING_DC_URL = 'https://staging.data-collector.vetvise.com'
const PREVIEW_DC_URL = 'https://preview.data-collector.vetvise.com'
const PROD_DC_URL = 'https://data-collector.vetvise.com'
const LOCAL_URL = 'http://localhost:8080'


import { version } from '../../package.json'
import { branch, deployment, product } from '../../branch.json'


function getOrigin() {
  if (deployment === 'native') {
    if (product === 'pig-brother') {
      if (branch === 'dev') return DEV_PB_URL
      if (branch === 'staging') return STAGING_PB_URL
      if (branch === 'preview') return PREVIEW_PB_URL
      return PROD_PB_URL
    } else if (product === 'data-collector') {
      if (branch === 'dev') return DEV_DC_URL
      if (branch === 'staging') return STAGING_DC_URL
      if (branch === 'preview') return PREVIEW_DC_URL
      return PROD_DC_URL
    }
    return LOCAL_URL
  } else {
    return window.location.origin
  }
}

function useBranchString() {
  return branch
}

function useVersionString() {
  return `${branch}-${deployment}-${version}`
}

function isInDataCollectorMode() {
  if (getOrigin() === LOCAL_URL) return false
  return [DEV_DC_URL, STAGING_DC_URL, PREVIEW_DC_URL, PROD_DC_URL].includes(getOrigin())
}

export { useBranchString, useVersionString, getOrigin, isInDataCollectorMode }
